<template>
  <div
    class="modal modal__right"
    id="previewModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon mb-0">
              <a @click="closeModal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__body" v-if="isLoading">
            <div class="d-flex align-items-center justify-content-center p5">
              <div
                class="spinner-border"
                style="width: 1rem; height: 1rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span
                class="text--black text--600 text--medium d-inline-flex ml-4"
                >Please wait</span
              >
            </div>
          </div>
          <div v-else class="modal__body">
            <iframe
              v-if="this.isDataPDF"
              :src="getLink"
              width="100%"
              height="500px"
              frameborder="0"
            >
            </iframe>
            <iframe
              v-if="this.isDataText"
              :src="getLink"
              width="100%"
              height="500px"
              frameborder="0"
            >
            </iframe>
            <VueDocPreview
              :value="imagePreviewDataName"
              type="office"
              v-if="this.isDataMicrosoftFile"
            />
            <picture class="d-block w-100 h-100 pb-5" v-if="this.isDataImage">
              <img :src="imagePreviewDataName" class="h-100 w-100" />
            </picture>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import VueDocPreview from "vue-doc-preview";
export default {
  name: "previewModal",
  props: {
    show: Boolean,
    isLoading: Boolean,
    close: Function,
    imagePreviewDataType: String,
    imagePreviewDataName: String,
  },
  data() {
    return {
      iconBase64: null,
    };
  },
  components: {
    VueDocPreview,
  },
  computed: {
    getLink() {
      return `https://docs.google.com/viewer?url=${this.imagePreviewDataName}&embedded=true`;
    },
    isDataImage() {
      return (
        this.imagePreviewDataType === "image/jpeg" ||
        this.imagePreviewDataType === "image/gif" ||
        this.imagePreviewDataType === "image/svg+xml" ||
        this.imagePreviewDataType === "image/png"
      );
    },
    isDataMicrosoftFile() {
      return (
        this.imagePreviewDataType === "application/msword" ||
        this.imagePreviewDataType === "application/mspowerpoint" ||
        this.imagePreviewDataType === "application/excel"
      );
    },
    isDataPDF() {
      return this.imagePreviewDataType === "application/pdf";
    },
    isDataText() {
      return (
        this.imagePreviewDataType === "text/plain" ||
        this.imagePreviewDataType === "text/richtext"
      );
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
